.App {
  text-align: center;
}

.App-logo, .App-logo-here {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }

  .App-logo-here {
    animation: App-logo-spin infinite 1s linear;
  }
}

.App-header {
  background-color: #F6EFE7;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  overflow: hidden;
}

.App-link {
  color: #61dafb;
}

.days-p {
  margin-top: 3rem;
  font-size: 3rem;
  font-weight: bold;
}

.days {
  font-size: 5rem;
  border-bottom: 0.5rem solid #000;
}

.days-p .until {
  font-size: 2.5rem;
  display: inline-block;
  margin-top: 1.5rem;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  .App-logo {
    height: 65vmin;
    pointer-events: none;
  }

  .days-p {
    margin-top: 2rem;
    font-size: 2rem;
    font-weight: bold;
  }

  .days {
    font-size: 3rem;
    border-bottom: 0.5rem solid #000;
  }

  .days-p .until {
    font-size: 1.25rem;
    display: inline-block;
    margin-top: 1.5rem;
  }

}

section {
  padding: 4rem 0rem 6rem;
  background-color: #beb7af;
}

.table {
  display: flex;
  justify-content: center;

  td {
    padding: 1rem;
  }
  table, th, td {
    border: 2px solid black;
    border-collapse: collapse;
  }
}

/* https://mycolor.space/?hex=%23F6EFE7&sub=1 */
